import baseColors from '@elegantstack/flow-ui-theme/src/theme/colors'

//Using tailwind preset colors
import colorPreset from '@elegantstack/flow-ui-theme/src/theme/color-preset'

const colors = {
  ...baseColors,
  alphaLighter: colorPreset.orange[100],
  alphaLight: colorPreset.orange[300],
  alpha: colorPreset.orange[600],
  alphaDark: colorPreset.orange[800],
  alphaDarker: colorPreset.orange[900]
}

export default colors
